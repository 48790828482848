import request from '@/utils/request'
// 设备结算日汇总 分页
export function deviceSettleDayPage(query) {
  return request({
    url: '/wisdom/wisdom-device-settle-day/page',
    method: 'get',
    params: query
  })
}

// 设备结算日汇总 导出
export function deviceSettleDayExport(data) {
  return request({
    url: '/wisdom/wisdom-device-settle-day/export',
    method: 'post',
    data
  })
}

// 设备结算日汇总 年月日 历史分页
export function deviceHjPage(query) {
  return request({
    url: '/wisdom/wisdom-device/hj/page',
    method: 'get',
    params: query
  })
}

// 设备上报明细 分页
export function deviceSettleRecordPage(query) {
  return request({
    url: '/wisdom/wisdom-device-settle-record/page',
    method: 'get',
    params: query
  })
}

// 设备上报明细 导出
export function deviceSettleRecordExport(data) {
  return request({
    url: '/wisdom/wisdom-device-settle-record/export',
    method: 'post',
    data
  })
}
