<template>
  <a-drawer
    width="1200"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>导出选项</b>
    </a-divider>
    <a-card :bordered="false">
      <a-row :gutter="24">
        <!-- 部门树 -->
        <a-col :span="6">
          <dept-tree ref="deptTree" :deptOptions="deptOptions" @select="clickDeptNode" />
        </a-col>
        <a-col :span="18">
          <!-- 条件搜索 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :md="10" :sm="24">
                  <a-form-item label="日期范围">
                    <a-range-picker style="width: 100%" v-model="dateRange" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="导出类型">
                    <a-select v-model="form.settleType" placeholder="请选择" allow-clear>
                      <a-select-option v-for="(item,key) in [{name:'年报',val:0},{name:'月报',val:1},{name:'日报',val:2}]" :key="key" :value="item.val">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="5" :sm="24">
                  <span class="table-page-search-submitButtons">
                    <!-- <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button> -->
                    <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 数据展示 -->
          <a-table
            :loading="loading"
            :size="tableSize"
            rowKey="id"
            :columns="columns"
            :data-source="list"
            :pagination="false"
            :bordered="tableBordered">
            <span slot="userInfo" slot-scope="text, record">
              <div class="spanBox">
                <span>{{ record.userName }}</span>
                <span>{{ record.userPhone }}</span>
              </div>
            </span>
          </a-table>
          <!-- 分页 -->
          <a-pagination
            class="ant-table-pagination"
            show-size-changer
            show-quick-jumper
            :current="queryParam.pageNum"
            :total="total"
            :page-size="queryParam.pageSize"
            :showTotal="total => `共 ${total} 条`"
            @showSizeChange="onShowSizeChange"
            @change="changeSize"
          />
        </a-col>
      </a-row></a-card>
    <div class="bottom-control">
      <a-space>
        <a-button type="primary" @click="handleExport">
          确定导出
        </a-button>
        <a-button type="dashed" @click="cancel">
          取消
        </a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>

import { tableMixin } from '@/store/table-mixin'
import { areaList } from '@/api/pages/region'
import DeptTree from '@/components/DeptTree/DeptTree'
import { deviceHjPage } from '@/api/pages/statistics'

export default {
  name: 'CreateForm',
  mixins: [tableMixin],
  props: {
    typeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    DeptTree
  },
  data () {
    return {
      open: false,
      // 部门树选项
      deptOptions: [{
        id: 0,
        label: '',
        children: []
      }],
      list: [],
      loading: false,
      total: 0,
      // 日期范围
      dateRange: [],
      queryParam: {
        pcaName: null,
        buildingId: null,
        communityId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '设备编号',
          dataIndex: 'devCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '水表编号',
          dataIndex: 'devSn',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备类型',
          dataIndex: 'typeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '绑定人信息',
          dataIndex: 'userInfo',
          scopedSlots: { customRender: 'userInfo' },
          align: 'center'
        }
      ],
      form: {
        settleType: 0,
        pcaName: null,
        buildingId: null,
        communityId: null,
        createBeginTime: null,
        createEndTime: null
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        pcaName: null,
        buildingId: null,
        communityId: null,
        pageNum: 1,
        pageSize: 10
      }
      this.getList()
    },
    /** 查询区域下拉树结构 */
    getDeptTree() {
      areaList().then(response => {
        this.deptOptions = response.data
      })
    },
    clickDeptNode(id, e) {
      console.log(id, e.selectedNodes[0].data.props)
      const label = e.selectedNodes[0].data.props.label
      const level = e.selectedNodes[0].data.props.level
      if (level < 4) {
        this.queryParam.pcaName = label
        this.form.pcaName = label

        this.queryParam.communityId = null
        this.form.communityId = null
        this.queryParam.buildingId = null
        this.form.buildingId = null
      } else if (level === 4) {
        this.queryParam.communityId = id
        this.form.communityId = id
      } else if (level === 5) {
        this.queryParam.buildingId = id
        this.form.buildingId = id
      }
      this.getList()
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
    },
    handle () {
      this.getDeptTree()
      this.resetQuery()
    },
    /** 查询参数列表 */
    getList () {
      this.open = true
      this.loading = true
      deviceHjPage(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      )
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '提示',
        content: '是否确认导出?',
        onOk () {
          console.log(that.dateRange)
          if (that.dateRange !== null && that.dateRange !== '' && that.dateRange.length === 2) {
            that.form.createBeginTime = that.dateRange[0] + ' 00:00:00'
            that.form.createEndTime = that.dateRange[1] + ' 23:59:59'
          }
          that.download('wisdom/wisdom-device-settle-day/export/ls', {
            ...that.form
          }, `抄收历史_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style scoped>
.spanBox{
  display: flex;
  flex-direction: column;
}
</style>
